import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { FaGlobe } from 'react-icons/fa';

const ChangeLanguage = () => {
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const savedLanguage = localStorage.getItem('userLanguage');
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, [i18n]);

    const languages = [
        { code: 'en', label: 'English' },
        { code: 'zh', label: '中文' },
        { code: 'ms', label: 'Malay' }
    ];

    return (
        <div className="relative">
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="flex items-center justify-between gap-2 px-3 py-1.5 bg-white/10 backdrop-blur-sm rounded-lg hover:bg-white/20 transition-all duration-200 min-w-[100px]"
            >
                <span className="text-white text-sm flex items-center gap-2">
                    <FaGlobe size={16} />{languages.find(lang => lang.code === i18n.language)?.label}
                </span>
                <svg
                    className={`w-3 h-3 text-white transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
            </button>

            {isOpen && (
                <div className="absolute right-0 mt-1 w-[100px] bg-[#1A1A1A] rounded-lg border border-gray-700 shadow-lg shadow-black/50 backdrop-blur-lg z-50">
                    {languages.map((language) => (
                        <button
                            key={language.code}
                            onClick={() => {
                                i18n.changeLanguage(language.code);
                                localStorage.setItem('userLanguage', language.code);
                                setIsOpen(false);
                            }}
                            className={`flex items-center gap-2 w-full text-left px-3 py-2 text-sm hover:bg-white/10 transition-all duration-200
                                ${i18n.language === language.code
                                    ? 'text-[#F0B90B] bg-white/5'
                                    : 'text-gray-200'
                                } ${language.code === languages[languages.length - 1].code ? 'rounded-b-lg' : ''}
                                ${language.code === languages[0].code ? 'rounded-t-lg' : ''}`
                            }
                        >
                            <FaGlobe size={16} />{language.label}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ChangeLanguage;