import React, { useEffect, useState, useCallback } from 'react';
import { useUser } from '../context/userContext';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { collection, query, orderBy, limit, getDocs, where } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { db } from '../firebase/firestore'; // Adjust path as needed

const Levels = ({ showLevel, setShowLevel }) => {
  const { t } = useTranslation();
  const { tapBalance } = useUser();

  const userLevels = [
    { name: "bronze", title: t('levels.Bronze'), icon: '/bronze.webp', tapBalanceRequired: 500000,search:"Bronze" },
    { name: "silver", title: t('levels.Silver'), icon: '/silver.webp', tapBalanceRequired: 1200000,search:"Silver" },
    { name: "gold", title: t('levels.Gold'), icon: '/gold.webp', tapBalanceRequired: 4000000,search:"Gold" },
    { name: "platinum", title: t('levels.Platinum'), icon: '/platinum.webp', tapBalanceRequired: 10000000,search:"Platinum" },
    { name: "diamond", title: t('levels.Diamond'), icon: '/diamond.webp', tapBalanceRequired: 25000000,search:"Diamond" },
    { name: "master", title: t('levels.Master'), icon: '/master.webp', tapBalanceRequired: 75000000,search:"Master" },
  ];
  const initialLevelIndex = userLevels.findIndex(level => tapBalance < level.tapBalanceRequired);
  const currentLevelIndex = initialLevelIndex === -1 ? userLevels.length - 1 : initialLevelIndex;

  const [displayedLevelIndex, setDisplayedLevelIndex] = useState(currentLevelIndex);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const isBackButtonSupported = window.Telegram.WebApp?.version >= 6.1;

  useEffect(() => {
    const webApp = window.Telegram?.WebApp;
    if (!webApp) return;

    if (showLevel) {
      // Ensure BackButton is initialized before using it
      if (webApp.BackButton) {
        webApp.BackButton.hide(); // Hide first to reset state
        webApp.BackButton.show();
        webApp.BackButton.onClick(() => setShowLevel(false));
      }
    } else {
      if (webApp.BackButton) {
        webApp.BackButton.hide();
        webApp.BackButton.offClick();
      }
    }

    return () => {
      if (webApp?.BackButton) {
        webApp.BackButton.hide();
        webApp.BackButton.offClick();
      }
    };
  }, [showLevel, setShowLevel]);

  const handlePrevious = () => {
    if (displayedLevelIndex > 0) {
      setDisplayedLevelIndex(displayedLevelIndex - 1);
    }
  };

  const handleNext = () => {
    if (displayedLevelIndex < userLevels.length - 1) {
      setDisplayedLevelIndex(displayedLevelIndex + 1);
    }
  };

  const currentLevel = userLevels[displayedLevelIndex];

  const formatNumber = (num) => {
    return new Intl.NumberFormat().format(num).replace(/,/g, ' ');
  };

  const fetchLeaderboardData = useCallback(async () => {
    const levelName = currentLevel.search;
    setLoading(true);

    try {
      const leaderboardQuery = query(
        collection(db, 'telegramUsers'),
        where('level.name', '==', levelName),
        orderBy('tapBalance', 'desc'),
        limit(10)
      );

      const querySnapshot = await getDocs(leaderboardQuery);
      const data = querySnapshot.docs.map(doc => ({
        id: doc.id,
        fullName: doc.data().fullName || 'Anonymous',
        tapBalance: doc.data().tapBalance || 0,
      }));

      setLeaderboardData(data);
    } catch (error) {
      console.error('Error fetching leaderboard data', error.message);
    } finally {
      setLoading(false);
    }
  }, [currentLevel.name]);

  const closeLevels = () => {
    setShowLevel(false);
    setModal(false);
  }

  useEffect(() => {
    if (showLevel) {
      fetchLeaderboardData();
    }
  }, [fetchLeaderboardData, showLevel]);

  return (
    <>
      {showLevel && (
      
        <div className="fixed left-0 right-0 z-20 top-0 bottom-0 flex justify-center taskbg px-[16px] h-full bg-gray-900">
          <div className='w-full flex flex-col w-full flex pt-6 flex-col space-y-6 overflow-y-auto pb-[100px] scroller'>
            {/* Level Navigation */}
            <div className="flex items-center justify-center space-x-4">
              <div className="flex flex-col items-center">
                <h1 className="text-2xl font-semibold text-white">{currentLevel.title}</h1>
                <p className="text-sm text-gray-400 leading-6 text-center px-3 pt-2 pb-8">
                  {t('level_description')}
                </p>
                <div className="relative flex items-center justify-center w-full">
                  <button
                    className={`absolute left-0 ${displayedLevelIndex === 0 ? 'hidden' : ''}`}
                    onClick={handlePrevious}
                  >
                    <MdOutlineKeyboardArrowLeft size={40} className="text-gray-500 hover:text-gray-300" />
                  </button>
                  <img
                    src={currentLevel.icon}
                    alt={currentLevel.name}
                    className="w-52 h-52"
                    style={{ width: '10rem', height: '10rem' }}
                  />


                  <button
                    className={`absolute right-0 ${displayedLevelIndex === userLevels.length - 1 ? 'hidden' : ''}`}
                    onClick={handleNext}
                  >
                    <MdOutlineKeyboardArrowRight size={40} className="text-gray-500 hover:text-gray-300" />
                  </button>
                </div>

                {displayedLevelIndex === currentLevelIndex &&
                  displayedLevelIndex < userLevels.length ? (
                  <div className="w-full px-10 mt-6">
                    <p className="text-base font-semibold text-gray-300 text-center">
                      {formatNumber(tapBalance)} / {formatNumber(currentLevel.tapBalanceRequired)}
                    </p>
                    <div className="flex items-center mt-2 bg-gray-700 rounded-md h-2">
                      <div
                        className="bg-green-500 h-full rounded-md"
                        style={{
                          width: `${(tapBalance / currentLevel.tapBalanceRequired) * 100}%`,
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="text-base font-medium text-gray-300 text-center mt-6">
                    {t('to')} {formatNumber(currentLevel.tapBalanceRequired)} {t('taps')}
                  </p>
                )}
              </div>
            </div>

            {/* Leaderboard Section */}
            <div className="bg-gray-800 rounded-lg shadow-lg p-4">
              <h2 className="text-xl font-bold text-center mb-4">
                {t('top_players_in')} <span className="text-yellow-400">{currentLevel.title}</span>
              </h2>
              <div className="overflow-y-auto max-h-60">
                <table className="w-full text-sm bg-gray-900 text-gray-300">
                  <thead className="bg-gray-800 text-gray-400">
                    <tr className='text-center'>
                      <th className="py-2 px-4">{t('rank')}</th>
                      <th className="py-2 px-4">{t('player')}</th>
                      <th className="py-2 px-4">{t('taps')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {leaderboardData.length > 0 ? (
                      leaderboardData.map((player, index) => (
                        <tr
                          key={player.id}
                          className={`${index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-900'
                            } hover:bg-gray-700`}
                        >
                          <td className="py-2 px-4 font-bold text-yellow-400">#{index + 1}</td>
                          <td className="py-2 px-4 truncate">{player.fullName}</td>
                          <td className="py-2 px-4 text-right text-green-400 font-bold">
                            {formatNumber(player.tapBalance)}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3} className="text-center py-4 text-gray-400 italic">
                          {t('no_players_yet')}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      )}
    </>
  );
};

export default Levels;
